import React from "react";
import styles from "./Title.module.css";

/**
 * Компонент для отображения заголовка с заданным уровнем и текстом.
 *
 * @param {string} className Класс заголовка.
 * @param {string} text Текст заголовка.
 * @param {string} tag Тег заголовка.
 * @param {number} level Уровень заголовка от 1 до 2.
 * @param {"left" | "center" | "right"} textAlign Выравнивание текста (например, "left", "center", "right").
 * @return {Element} Возвращает элемент заголовка.
 */
const Title = ({ className, children: text, tag, level, textAlign }) => {
  const renderTitle = () => {
    let classNames = `${styles.title} ${styles["size" + level]}`;

    if (className) {
      classNames += ` ${className}`;
    }

    return <span className={classNames} style={{ textAlign: textAlign }}>{text}</span>;
  }

  const tags = {
    "h1": <h1>{renderTitle()}</h1>,
    "h2": <h2>{renderTitle()}</h2>,
    "h3": <h3>{renderTitle()}</h3>
  };

  return tags[tag] ?? renderTitle();
}

export default Title;