import React from "react";
import styles from "./Tag.module.css";
import IconButton from "../IconButton/IconButton";
import ButtonType from "../../../api/constants/ButtonType";

/**
 * Компонент для отображения тега.
 *
 * @param {string} text Текст тега.
 * @param {Function} onClick Обратный вызов, вызываемый при нажатии на тег.
 * @param {Function} onClose Обратный вызов, вызываемый при нажатии кнопки закрытия (крестика).
 * @returns {React.JSX.Element} Элемент тега.
 * @constructor
 */
const Tag = ({ children: text, onClick, onClose }) => {
  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  }

  return (
    <div className={styles.wrapper} onClick={onClick}>
      {text}
      <IconButton
        className={styles.closeButton}
        type={ButtonType.TRANSPARENT}
        icon={"x-close"}
        onClick={handleClose}
      />
    </div>
  );
}

export default Tag;