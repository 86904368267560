import React from "react";
import styles from "./Notification.module.css";
import Link from "../Link";
import Title from "../Title/Title";
import IconButton from "../IconButton/IconButton";

/**
 * Компонент для отображения важных уведомлений.
 *
 * @param {string} className Класс уведомления.
 * @param {string} title Текст заголовка.
 * @param {string} text Текст сообщения.
 * @param {string} actionButtonText Текст кнопки действия.
 * @param {string} actionButtonUrl Ссылка, открываемая при нажатии кнопки действия.
 * @param {Function} onClose Обратный вызов, вызываемый при нажатии кнопки закрытия.
 * @param style
 * @return {Element} Компонент уведомления.
 * @constructor
 */
const Notification = ({ className, title, children: text, actionButtonText, actionButtonUrl, style, onClose }) => {
  const isTitleVisible = title && title !== "";
  const isActionButtonVisible = actionButtonUrl && actionButtonUrl !== "";
  let classNames = styles.wrapper;

  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <div className={classNames} style={style}>
      {/*<IconButton className={styles.closeButton} icon={"circle-xmark"} onClick={onClose} />*/}
      {isTitleVisible && <Title className={styles.title} level={2}>{title}</Title>}
      {text}
      {isActionButtonVisible && (
        <Link className={styles.actionButton} url={actionButtonUrl} newTab={true}>{actionButtonText}</Link>
      )}
    </div>
  );
}

export default Notification;