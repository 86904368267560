/**
 * Объект, представляющий типы кнопок.
 */
const ButtonType = {
  /**
   * Заполненная кнопка.
   * @type {string}
   */
  FILLED: "filled",

  /**
   * Контурная кнопка.
   * @type {string}
   */
  OUTLINED: "outlined",

  /**
   * Тональная кнопка.
   * @type {string}
   */
  TONAL: "tonal",

  /**
   * Прозрачная кнопка.
   * @type {string}
   */
  TRANSPARENT: "transparent"
};

export default ButtonType;