import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../App";
import { useSearchParams } from "react-router-dom";
import { useAppNav } from "../../../api/hooks/useAppNav";
import { useCopyToClipboard } from "usehooks-ts";
import styles from "./TimetablePage.module.css";
import TimetableObject from "../../../api/data/TimetableObject";
import WeekSwitcherItem from "../../../api/data/WeekSwitcherItem";
import ListItem from "../../../api/data/ListItem";
import Spinner from "../../components/Spinner/Spinner";
import Icon from "../../components/Icon";
import Title from "../../components/Title/Title";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import request from "../../../api/utils/request";
import dateTime from "../../../api/utils/dateTime";
import Tooltip from "../../components/Tooltip/Tooltip";
import Link from "../../components/Link";
import useTitle from "../../../api/hooks/useTitle";
import BottomSheet from "../../components/BottomSheet";
import useResponse from "../../../api/hooks/useResponse";
import IconButton from "../../components/IconButton/IconButton";
import ButtonType from "../../../api/constants/ButtonType";
import Notification from "../../components/Notification/Notification";
import useScrollPos from "../../../api/hooks/useScrollPos";

/**
 * Компонент страницы расписания.
 *
 * @return {Element} Элемент страницы.
 */
const TimetablePage = () => {
  const { setErrorMessage } = useAppContext();
  const { navToSearch, navToTimetable } = useAppNav();
  const { isDesktop, isMobile } = useResponse();
  const { scrollY } = useScrollPos();

  const [notificationState, setNotificationState] = useState({
    isVisible: false,
    topPosition: true,
    title: "",
    text: "",
    actionButtonText: "",
    actionButtonUrl: ""
  });
  const [searchParams] = useSearchParams();
  const [headerMenuItems, setHeaderMenuItems] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [sidebarFixedYPos, setSidebarFixedYPos] = useState(0);
  const [timetableObject, setTimetableObject] = useState(null);
  const [currentWeekISO, setCurrentWeekISO] = useState("");
  const [selectedWeekISO, setSelectedWeekISO] = useState("");
  const [weekSwitcherItem, setWeekSwitcherItem] = useState(null);
  const [isDetailsSheetOpen, setIsDetailsSheetOpen] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isToTopVisible, setIsToTopVisible] = useState(false);
  const [isSidebarFixed, setIsSidebarFixed] = useState(false);
  const [isDaySelected, setIsDaySelected] = useState(false);

  const weekContainerRef = useRef(null);

  const title = timetableObject?.[`${isMobile ? "shortName" : "name"}`];

  // Устанавливаем заголовок документа
  useTitle(title);

  useEffect(() => {
    const data = responseData?.notification;

    updateNotificationState({
      isVisible: data !== null,
      topPosition: data?.topPosition === true,
      title: data?.title,
      text: data?.text,
      actionButtonText: data?.actionButtonText,
      actionButtonUrl: data?.actionButtonUrl
    });
  }, [responseData?.notification]);

  // Отслеживаем изменение параметров URL
  useEffect(() => {
    const type = searchParams.get("type");
    const strId = searchParams.get("id");

    if (type && strId) {
      const id = parseInt(strId);
      const weekISO = searchParams.get("weekISO") ?? "";

      fetchTimetable(type, id, weekISO);
    }

    return () => request.cancel();
  }, [searchParams]);

  useEffect(() => {
    const windowH = Math.round(window.innerHeight + scrollY);
    const scrollH = document.documentElement.scrollHeight - 2;

    setIsSidebarFixed(isDesktop && (scrollY > sidebarFixedYPos));
    setIsScrolledToBottom(windowH >= scrollH);
    setIsToTopVisible(scrollY > 0);
  }, [scrollY, isDesktop, sidebarFixedYPos, timetableObject]);

  useEffect(() => {
    if (weekContainerRef.current) {
      const header = document.querySelector("header");
      const headerH = header?.getBoundingClientRect()?.height;
      const firstDayItem = document.querySelector("ul > li:first-child > ul > li:first-child");
      const firstDayItemRect = firstDayItem?.getBoundingClientRect();
      const y = firstDayItemRect?.top + scrollY - headerH - 22; // 44px (отступ над сайдбаром) / 2

      setSidebarFixedYPos(y);
    }
  }, [weekContainerRef, scrollY]);

  /**
   * Строит элементы меню для шапки на основе переданных объектов расписания.
   *
   * @param {Object[]} objects Массив объектов, используемых для построения элементов меню.
   */
  const buildHeaderMenuItems = (objects) => {
    const items = objects.map(object => {
      return new ListItem(object["name"], ListItem.ICON_NONE, object);
    });

    setHeaderMenuItems(items);
  }

  /**
   * Сбрасывает состояние переменных.
   */
  const resetStates = () => {
    setIsDaySelected(false);
    setResponseData(null);
    setWeekSwitcherItem(null);
    setCurrentWeekISO("");
    setSelectedWeekISO("");
    //setErrorMessage("");
  }

  /**
   * Загружает расписание на указанную неделю и обновляет состояние компонента.
   *
   * @param {string} type Тип объекта расписания.
   * @param {number} id ID объекта расписания.
   * @param {string} [weekISO=selectedWeekISO] ISO загружаемой недели расписания. По умолчанию равняется выбранной.
   */
  const fetchTimetable = (type, id, weekISO = selectedWeekISO) => {
    resetStates();
    request.getTimetable(type, id, weekISO, (response, error) => {
      //setIsScrolledToBottom(false);

      if (response && response["object"]) {
        const object = TimetableObject.parse(response["object"]);
        const weeks = response["weeks"];
        const selectedWeek = weeks["selected"];
        const weekItem = WeekSwitcherItem.parse(selectedWeek);

        setTimetableObject(object);
        setResponseData(response);
        setCurrentWeekISO(weeks["current"]["iso"]);
        setSelectedWeekISO(selectedWeek["iso"]);
        buildHeaderMenuItems(response["relatedObjects"]);
        setWeekSwitcherItem(weekItem);
      } else {
        setErrorMessage(error);
      }
    });
  }

  /**
   * Обновляет состояние всплывающего уведомления.
   *
   * @param {Object} newState Объект с новыми значениями для обновления состояния.
   */
  const updateNotificationState = (newState) => {
    setNotificationState(prevState => ({
      ...prevState,
      ...newState
    }));
  }

  /**
   * Отображает уведомление на экране.
   *
   * @return {Element} Элемент уведомления.
   */
  const renderNotification = () => {
    const className = (notificationState.topPosition) ? styles.notificationTop : styles.notificationBottom;

    const handleClose = () => {
      updateNotificationState({ isVisible: false });
    }

    return (
      <>
        {notificationState.isVisible && (
          <Notification
            className={className}
            title={notificationState.title}
            actionButtonText={notificationState.actionButtonText}
            actionButtonUrl={notificationState.actionButtonUrl}
            onClose={handleClose}
          >
            {notificationState.text}
          </Notification>
        )}
      </>
    );
  }

  /**
   * Компонент для отображения списка занятий.
   *
   * @return {Element} Элемент списка.
   */
  const LessonList = () => {
    /**
     * Компонент для отображения заголовка с датами.
     *
     * @param {string} date Дата, отображаемая в заголовке.
     * @param {boolean} mark Флаг, указывающий на отображение маркера слева.
     * @return {Element} Элемент заголовока.
     */
    const DateTitle = ({ children: date, mark }) => {
      return (
        <div className={styles.dayHeader}>
          {mark && <Icon name={"circle"} />}
          {date}
        </div>
      );
    }

    /**
     * Компонент для отображения занятий дня недели.
     *
     * @param {string} children Заголовок дня недели.
     * @param {string} date Дата дня недели.
     * @param {Object[]} lessons Массив данных объектов занятий.
     * @return {Element} Элемент дня недели.
     */
    const Day = ({ children, date, lessons }) => {
      const itemRef = useRef(null);
      const serverDate = responseData["serverTime"];
      const isCurrentDay = date ? dateTime.isDateEquals(date, serverDate) : false;

      useEffect(() => {
        if (!isDaySelected && isCurrentDay) {
          const y = itemRef.current.offsetTop - 144; // 144 - высота шапки + вертикальные отступы

          window.scrollTo(0, y);
          setIsDaySelected(true);
        }
      }, [isCurrentDay]);

      /**
       * Компонент для отображения ячейки занятия.
       *
       * @param {Object} props Свойства компонента.
       * @return {Element} Элемент ячейки.
       */
      const Cell = (props) => {
        /**
         * Компонент для отображения типа иконки занятия.
         *
         * @return {Element} Элемент иконки.
         */
        const LessonIcon = () => {
          const [isTooltipShown, setIsTooltipShown] = useState(false);

          const names = {
            lesson: {
              0: { name: "Другое", icon: "sign-question" },
              1: { name: "Лекция", icon: "person-wave" },
              2: { name: "Практическое", icon: "pencil" },
              3: { name: "Лабораторное", icon: "flask" },
              4: { name: "Экзамен", icon: "graduation-hat" },
              5: { name: "Зачёт", icon: "text-book" },
              6: { name: "Дифференцированный зачёт", icon: "number-book" },
              7: { name: "Консультация", icon: "pin" },
              8: { name: "Физкультура", icon: "dumbbell" },
            },
            tip: {
              0: { name: "", icon: "sign-question" },
              1: { name: "", icon: "megaphone" },
              2: { name: "", icon: "sun" },
              3: { name: "", icon: "party-popper" },
              4: { name: "", icon: "alarm-clock" },
              5: { name: "", icon: "window" },
            }
          };
          const type = props.type;
          const subtype = props.subtype;
          const typeClass = styles[type + "Icon"];
          const subtypeClass = styles[type + "IconSubtype" + subtype];
          const tooltipTitle = names[type][subtype].name;
          const iconClass = typeClass + " " + subtypeClass;
          const iconName = names[type][subtype].icon;

          const handleMouseOver = () => setIsTooltipShown(tooltipTitle !== "");

          const handleMouseLeave = () => setIsTooltipShown(false);

          return (
            <div className={styles.lessonIconContainer}>
              <div className={iconClass} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                <Icon name={iconName} />
              </div>
              {isTooltipShown && (
                <div className={styles.tooltipWrapper}>
                  <Tooltip title={tooltipTitle} />
                </div>
              )}
            </div>
          );
        }

        /**
         * Компонент для отображения перечисляемого списка.
         *
         * @param {Object[]} items Массив данных элементов.
         * @return {Element} Элемент списка значений через запятую.
         */
        const EnumList = ({ items }) => {
          /**
           * Компонент для отображения элемента списка.
           *
           * @param {string} text Текст элемента.
           * @param {Object} data Данные элемента.
           * @param {number} index Индекс элемента.
           * @return {Element} Элемент списка.
           */
          const Item = ({ children: text, data, index }) => {
            const handleClick = () => {
              if (data["subgroups"]) {
                navToSearch(data["name"]);
              } else {
                navToTimetable(data["type"], data["id"]);
              }
            }

            if (index !== items.length - 1) {
              text += ",";
            }

            return (
              <div className={styles.lessonLink} onClick={handleClick}>
                {text}
              </div>
            );
          }

          return (
            <>
              {items?.map((item, index) => (
                <Item key={index} data={item} index={index}>{item["name"]}</Item>
              ))}
            </>
          );
        }

        const Header = () => {
          return (
            <div className={styles.lessonHeader}>
              <EnumList items={props.groups} />
            </div>
          );
        }

        const Body = () => {
          const Room = ({ data }) => {
            const object = TimetableObject.parse(data);
            const classNames = `${styles.lessonLink} ${styles.lessonRoom}`;

            const handleClick = () => navToTimetable(data["type"], data["id"]);

            return <div className={classNames} onClick={handleClick}>{object.shortName}</div>;
          }

          return (
            <div className={styles.lessonBody}>
              <div className={styles.lessonTitle}>{props.children}</div>
              {props.room && <Room data={props.room}/>}
            </div>
          );
        }

        const Footer = () => {
          /**
           * Компонент для отображения описания подзаголовка элемента.
           *
           * @return {Element} Элемент описания.
           */
          const Description = () => {
            return (
              <div className={styles.lessonDescription}>
                {props.subtitles ? <EnumList items={props.subtitles} /> : props.subtitle}
              </div>
            );
          }

          /**
           * Компонент для отображения времени и статуса лекции.
           *
           * @param {number} status Статус лекции.
           * @param {string} value Время начала и окончания лекции.
           * @return {Element} Элемент времени либо статуса.
           */
          const Time = ({ status, value }) => {
            const values = {
              0: value,
              1: <span className={`${styles.lessonStatus} ${styles.lessonStatus1}`}>Перенесено</span>,
              2: <span className={`${styles.lessonStatus} ${styles.lessonStatus2}`}>Отменено</span>
            };

            return <div className={styles.lessonTime}>{values[status]}</div>;
          }

          return (
            <div className={styles.lessonFooter}>
              <Description />
              <Time status={props.status} value={props.time} />
            </div>
          );
        }

        return (
          <li>
            <div className={styles.lessonCell}>
              <div className={styles.lessonCellLeft}>
                <div className={styles.lessonNumber}>{props.number}</div>
                <LessonIcon/>
              </div>
              <div className={styles.lessonCellRight}>
                <Header />
                <Body />
                <Footer />
              </div>
            </div>
            {props.separator && <div className={styles.separator} />}
          </li>
        );
      }

      const Item = ({ data, separator }) => {
        const content = data["content"];
        const type = data["type"];
        const subtype = content["subtype"];

        if (type === "lesson") {
          // Лекция
          const timeSlot = content["timeSlot"];
          const timeRange = dateTime.parseTimeRange(timeSlot["startTime"], timeSlot["endTime"]);
          const subtitleContent = {
            "students": content["teachers"],
            "teachers": content["groupsOnLesson"],
            "classrooms": content["teachers"]
          };

          return (
            <Cell
              type={type}
              subtype={subtype}
              subtitles={subtitleContent[timetableObject.type]}
              groups={timetableObject.isClassroom ? content["groupsOnLesson"] : null}
              number={timeSlot["lessonNumber"]}
              room={!timetableObject.isClassroom ? content["classroom"] : null}
              time={timeRange}
              status={content["status"]}
              separator={separator}
            >
              {content["discipline"]["name"]}
            </Cell>
          );
        } else {
          // Подсказка
          return (
            <Cell
              type={type}
              subtype={subtype}
              subtitle={content["subtitle"]}
              separator={separator}
            >
              {content.title}
            </Cell>
          );
        }
      }

      const lastIndex = lessons.length - 1;

      return (
        <li className={styles.dayContainer} ref={itemRef}>
          <DateTitle mark={isCurrentDay}>{children}</DateTitle>
          <ul className={styles.dayBody}>
            {lessons?.map((lesson, index) =>
              <Item data={lesson} separator={index !== lastIndex} key={index}/>
            )}
          </ul>
        </li>
      );
    }

    /**
     * Компонент для отображения сообщения об отсутствии расписания.
     *
     * @return {Element} Элемент сообщения.
     */
    const NoTimetable = () => {
      const selectedWeek = responseData["weeks"]["selected"];
      const startDate = selectedWeek["startDate"];
      const endDate = selectedWeek["endDate"];
      const date = dateTime.parseDayMonthRange(startDate, endDate, true);

      return (
        <div className={styles.dayContainer}>
          <DateTitle>{date}</DateTitle>
          <div className={styles.noTimetableWrapper}>
            <div className={styles.noTimetableContainer}>
              <Icon name={"calendar-minus"} />
              <div className={styles.noTimetableMessage}>
                <Title level={1} textAlign={"center"}>Расписания нет</Title>
                <div className={styles.noTimetableSubtitle}>
                  На выбранной неделе занятий не запланировано
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    /**
     * Компонент для отображения расписания на неделю.
     *
     * @return {Element} Элемент расписания.
     */
    const Timetable = () => {
      const timetable = responseData["timetable"];

      return (
        <>
          {timetable.map((day, index) => (
            <Day key={index} date={day.date} lessons={day.items}>
              {dateTime.parseDate(day.date)}
            </Day>
          ))}
        </>
      );
    }

    return (
      <ul className={styles.weekContainer} ref={weekContainerRef}>
        {responseData["timetable"]?.length > 0 ? <Timetable /> : <NoTimetable />}
      </ul>
    );
  };

  /**
   * Компонент боковой панели страницы справа.
   *
   * @return {Element} React-элемент панели.
   */
  const SideBar = () => {
    /**
     * Компонент для переключения расписания по неделям.
     *
     * @return {Element} Элемент переключателя.
     */
    const WeekSwitcher = () => {
      /**
       * Загружает расписание на неделю в зависимости от типа.
       *
       * @param {string} type Тип расписания ("selected", "prev", "next", "current").
       */
      const loadWeekTimetable = (type) => {
        const weekISO = responseData["weeks"][type]["iso"];

        navToTimetable(timetableObject.type, timetableObject.id, weekISO);
      }

      /**
       * Компонент кнопки для переключения следующей и предыдущей недели.
       *
       * @param {string} type Тип кнопки ("next" - далее, "prev" - назад).
       * @return {Element} Элемент кнопки.
       */
      const Button = ({ type }) => {
        const handleClick = () => loadWeekTimetable(type);

        const iconNameSuffix = (type === "next") ? "right" : "left";

        return <IconButton icon={`chevron-${iconNameSuffix}`} type={ButtonType.TONAL} onClick={handleClick} />
      }

      /**
       * Компонент кнопки переключения на текущую неделю.
       *
       * @return {Element} Элемент кнопки.
       */
      const CurrentWeekButton = () => {
        const isCurrentWeek = selectedWeekISO === currentWeekISO;

        const handleClick = () => loadWeekTimetable("current");

        return (
          <button className={styles.currentWeekButton} onClick={handleClick} disabled={isCurrentWeek}>
            {isCurrentWeek ? "Текущая неделя" : "На текущую"}
            <Icon name={"flip-backward"} color={"currentColor"} />
          </button>
        );
      }

      const DetailsButton = () => {
        let classNames = styles.detailsButton;

        const handleClick = () => setIsDetailsSheetOpen(true);

        if (isScrolledToBottom) {
          classNames += ` ${styles.detailsButtonOutline}`;
        }

        return (
          <button className={classNames} onClick={handleClick}>
            <Icon name={"info-circle"} />
          </button>
        );
      }

      return (
        <div className={styles.weekSwitcher}>
          <div className={styles.weekSwitcherHeader}>
            <div className={styles.sidebarTitle}>{weekSwitcherItem?.title}</div>
            {isDesktop && <CurrentWeekButton />}
          </div>
          <div className={styles.weekSwitcherBody}>
            {!isDesktop && <CurrentWeekButton />}
            <div className={styles.weekSwitcherPaginator}>
              <Button type={"prev"} />
              <div className={styles.weekSwitcherDateRange}>
                {weekSwitcherItem?.[`${isMobile ? "shortWeekRange" : "weekRange"}`]}
              </div>
              <Button type={"next"} />
            </div>
            <DetailsButton />
          </div>
        </div>
      );
    }

    /**
     * Компонент для отображения подробной информации об объекте расписания.
     *
     * @returns {Element} Контейнер блока подробной информации.
     */
    const Details = () => {
      const Content = () => {
        /**
         * Компонент для отображения ссылки на расписание.
         *
         * @param {string} url URL расписания.
         * @return {Element} Элемент блока со ссылкой.
         */
        const TimetableLink = ({ url }) => {
          const linkInputRef = useRef(null);

          /**
           * Выделяет весь текст ссылки.
           */
          const selectAll = () => linkInputRef.current.select();

          /**
           * Компонент кнопки копирования ссылки.
           *
           * @return {Element} Элемент кнопки.
           */
          const CopyButton = () => {
            const [isUrlCopied, setUrlCopied] = useState(false);

            const icon = isUrlCopied ? "check" : "copy";
            const [copiedText, copyToClipboard] = useCopyToClipboard();

            const handleClick = () => {
              selectAll();
              copyToClipboard("https://" + url)
                .then(success => {
                  if (success) {
                    setUrlCopied(true);
                    setTimeout(() => {
                      setUrlCopied(false);
                    }, 2000);
                  } else {
                    setErrorMessage("Не удалось скопировать ссылку в буфер обмена");
                  }
                });
            };

            return (
              <button className={styles.copyLinkButton} onClick={handleClick} disabled={isUrlCopied}>
                <Icon className={styles.linkIcon} name={icon} size={20} />
              </button>
            );
          }

          return (
            <>
              {url && (
                <div className={styles.linkContainer}>
                  <div className={styles.sidebarTitle}>Ссылка на расписание</div>
                  <div className={styles.linkInputGroup}>
                    <Icon className={styles.linkIcon} name={"link"} size={20} />
                    <input
                      ref={linkInputRef}
                      className={styles.linkInput}
                      value={url}
                      readOnly={true}
                      onClick={selectAll}
                    />
                    <CopyButton />
                  </div>
                </div>
              )}
            </>
          );
        }

        /**
         * Компонент для отображения списка пунктов информации.
         *
         * @param {Object[]} items Массив элементов списка.
         * @returns {Element} Элемент списка.
         */
        const List = ({ items }) => {
          /**
           * Компонент для отображения отдельного элемента информации.
           *
           * @param {string} name Название поля.
           * @param {string} separator Разделитель значений.
           * @param {Object[]} values Значения поля.
           * @return {Element} React-элемент элемента информации.
           */
          const Item = ({ name, separator, values}) => {
            const isNewLine = separator === "\n";
            const valueClassNames = `${styles.aboutValue} ${!isNewLine ? styles.aboutValueRow : ""}`;
            const lastIndex = values.length - 1;

            const Value = ({ children, url }) => {
              const isSite = !url.startsWith("tel") && !url.startsWith("mailto");

              return (
                <div className={valueClassNames}>
                  {url !== "" ? (
                    <Link url={url} newTab={isSite}>{children}</Link>
                  ) : (
                    <div className={styles.aboutValueText}>{children}</div>
                  )}
                </div>
              );
            }

            return (
              <div className={styles.aboutItem}>
                <div className={styles.aboutName}>{name}</div>
                {values?.map(({url, value}, index) => (
                  <React.Fragment key={index}>
                    <Value url={url}>{value}</Value>
                    {index !== lastIndex && !isNewLine && <span key={index}>{separator}</span>}
                  </React.Fragment>
                ))}
              </div>
            );
          }

          return (
            <div className={styles.aboutContainer}>
              <div className={styles.sidebarTitle}>Подробности</div>
              {items?.map(({ name, separator, values }, index) => (
                <Item key={index} name={name} separator={separator} values={values}/>
              ))}
            </div>
          );
        }

        return (
          <>
            <TimetableLink url={url} />
            <List items={detailsItems} />
          </>
        );
      }

      const Block = () => {
        return (
          <div className={styles.detailsContainer}>
            <Content />
          </div>
        );
      }

      const Sheet = () => {
        const handleClose = () => setIsDetailsSheetOpen(false);

        return (
          <BottomSheet isOpen={isDetailsSheetOpen} onClose={handleClose}>
            <div className={styles.detailsSheet}>
              <Content />
            </div>
          </BottomSheet>
        );
      }

      return !isDesktop ? <Sheet /> : <Block />;
    }

    const url = responseData?.url ?? null;
    const detailsItems = responseData?.object?.details ?? null;
    const classNames = `${styles.sidebar} ${isSidebarFixed ? styles.fixed : styles.absolute}`;

    return (
      <div>
        <div className={classNames}>
          <WeekSwitcher />
          <Details />
          {/*{isTablet && isScrolledToBottom (*/}
          {/*  <div className={isScrolledToBottom ? styles.slideDown : styles.slideUp}>*/}
          {/*    <Footer logo={true} wide={true} toTopButton={isToTopVisible} />*/}
          {/*  </div>*/}
          {/*)}*/}
          {(!isDesktop && isScrolledToBottom) && <Footer logo={true} responsiveWide={true} toTopButton={isToTopVisible} />}
        </div>
      </div>
    );
  }

  return (
    <>
      <Header
        backButton={true}
        title={title}
        menuItems={headerMenuItems}
        onClickMenuItem={navToTimetable}
      />
      <main className={styles.main}>
        {!responseData ? (
          <Spinner />
        ) : (
          <div className={styles.content}>
            {notificationState.topPosition && renderNotification()}
            <div className={styles.contentBody}>
              <LessonList />
              <SideBar />
            </div>
            {!notificationState.topPosition && renderNotification()}
          </div>
        )}
      </main>
      {(responseData && isDesktop) && <Footer logo={true} responsiveWide={false} toTopButton={isToTopVisible} />}
    </>
  );
};

export default TimetablePage;