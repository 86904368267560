import { useLocalStorage } from "usehooks-ts";

/**
 * Хук для управления недавно открытыми объектами расписания в хранилище.
 */
const useRecentStorage = () => {
  const [items, setItems] = useLocalStorage("recentObjects", []);

  /**
   * Сохраняет новый объект расписания в хранилище.
   *
   * @param {TimetableObject} obj Сохраняемый объект расписания.
   */
  const addItem = (obj) => {
    const foundItem = items.findIndex(item => obj.equals(item));

    if (foundItem === -1) {
      // Если элемент ещё не добавлен и кол-во добавленных меньше 5
      if (items.length > 4) {
        const lastIndex = items.length - 1;

        deleteItem(lastIndex);
      }
    } else {
      // Если элемент уже добавлен
      deleteItem(foundItem);
    }

    setItems((prevItems) => [obj, ...prevItems]);
  };

  /**
   * Удаляет объект расписания из хранилища.
   *
   * @param {number} index Индекс удаляемого объекта расписания.
   */
  const deleteItem = (index) => {
    if (items.length > 0) {
      const newItems = items.filter((_, i) => i !== index);

      setItems(newItems);
   }
  };

  return { items, addItem, deleteItem };
}

export default useRecentStorage;