/**
 * Класс, представляющий объект расписания.
 */
export default class TimetableObject {
  constructor(id, type, name, shortName) {
    /**
     * Тип объекта.
     *
     * @type {string}
     * @description Например, "students", "teachers", "classrooms".
     */
    this.type = type;

    /**
     * Уникальный идентификатор объекта.
     *
     * @type {number}
     */
    this.id = id;

    /**
     * Название объекта.
     *
     * @type {string}
     */
    this.name = name;

    /**
     * Сокращённое название объекта.
     *
     * @type {string}
     */
    this.shortName = shortName;

    /**
     * Флаг, указывающий, что объект является аудиторией.
     *
     * @type {boolean}
     */
    this.isClassroom = this.type === "classrooms";
  }

  equals(obj) {
    return this.id === obj.id && this.type === obj.type;
  }

  /**
   * Создает новый экземпляр объекта на основе переданных данных.
   *
   * @param {Object} obj Объект с данными.
   * @return {TimetableObject} Экземпляр объекта расписания.
   */
  static parse(obj) {
    return new this(obj.id, obj.type, obj.name, obj.shortName);
  }
}