import React from "react";
import styles from "./Recent.module.css";
import Tag from "../../../../components/Tag/Tag";
import useRecentStorage from "../../../../../api/hooks/useRecentStorage";
import { useAppNav } from "../../../../../api/hooks/useAppNav";
import TimetableObject from "../../../../../api/data/TimetableObject";

/**
 * Компонент для отображения недавно открытых объектов расписания.
 *
 * @return {Element} Элемент недавно открытых объектов расписания.
 * @constructor
 */
const Recent = () => {
  const { items, addItem, deleteItem } = useRecentStorage();
  const { navToTimetable } = useAppNav();

  const handleClickItem = (index) => {
    const obj = TimetableObject.parse(items[index]);

    addItem(obj);
    navToTimetable(obj.type, obj.id);
  }

  return (
    <>
      {items.length > 0 && (
        <div className={styles.wrapper}>
          <span className={styles.title}>Открывали недавно</span>
          <div className={styles.tagsContainer}>
            {items.map((item, index) => {
              return (
                <Tag
                  onClick={() => handleClickItem(index)}
                  onClose={() => deleteItem(index)}
                  key={index}>
                  {item.shortName}
                </Tag>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default Recent;