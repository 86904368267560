import React from "react";
import styles from "./IconButton.module.css";
import Button from "../Button/Button";
import Icon from "../Icon";

/**
 * Компонент кнопки с иконкой.
 *
 * @param {string} className Класс кнопки.
 * @param {string} icon Название иконки.
 * @param {string} type Тип кнопки.
 * @param {boolean} isClickable Флаг, указывающий на кликабельность кнопки.
 * @param {Function} onClick Обратный вызов, вызываемый при нажатии кнопки.
 * @returns {Element}
 * @constructor
 */
const IconButton = ({ className, icon, type, isClickable, onClick }) => {
  let classNames = styles.wrapper;

  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <Button className={classNames} type={type} isClickable={isClickable} onClick={onClick}>
      <Icon name={icon} color={"currentColor"} />
    </Button>
  );
}

export default IconButton;