import { useEffect, useState } from "react";

/**
 * Хук для отслеживания текущей позиции скролла по осям X и Y.
 *
 * @return {{scrollY: number, scrollX: number}}
 */
const useScrollPos = () => {
  const [pos, setPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const type = "scroll";
    const handleScroll = () => {
      setPos({
        x: window.scrollX,
        y: window.scrollY,
      });
    };

    window.addEventListener(type, handleScroll);

    return () => {
      window.removeEventListener(type, handleScroll);
    };
  }, []);

  return { scrollX: pos.x, scrollY: pos.y };
};

export default useScrollPos;