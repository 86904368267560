import React, { useState } from "react";
import ButtonType from "../../../../../api/constants/ButtonType";
import styles from "../../blocks/Additional/Additional.module.css";
import IconButton from "../../../../components/IconButton/IconButton";
import Button from "../../../../components/Button/Button";
import Link from "../../../../components/Link";
import Icon from "../../../../components/Icon";
import Title from "../../../../components/Title/Title";
import Spinner from "../../../../components/Spinner/Spinner";
import useResponse from "../../../../../api/hooks/useResponse";

/**
 * Компонент для отображения блока с дополнительными разделами (информацией).
 *
 * @return {Element} Элемент секции.
 * @constructor
 */
const Additional = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { isDesktop } = useResponse();

  /**
   * Компонент для осуществления навигации по вкладкам.
   *
   * @return {Element} Элемент навигации.
   * @constructor
   */
  const Nav = () => {
    const Item = ({ index, children: text }) => {
      const isActive = index === activeTab;
      const buttonType = isActive ? ButtonType.FILLED : ButtonType.TONAL;
      let classNames = styles.navItem;

      if (isActive) {
        classNames += ` ${styles.active}`;
      }

      const handleClick = () => {
        //setIsLoading(true);
        setActiveTab(index);
      }

      return (
        <li className={classNames} onClick={handleClick}>
          {text}
          <IconButton
            icon={`chevron-${isDesktop ? "right" : "down"}`}
            type={buttonType}
            isClickable={false}
          />
        </li>
      );
    }

    return (
      <nav className={styles.navTabs}>
        <Item index={0}>Расписание звонков</Item>
        <Item index={1}>Буквенные обозначения кабинетов</Item>
        <Item index={2}>Расписание проведения ГИА</Item>
      </nav>
    );
  }

  /**
   * Компонент для отображения учебных корпусов и их обозначений.
   *
   * @return {Element} Элемент содержимого вкладки "Буквенные обозначения кабинетов".
   * @constructor
   */
  const Campuses = () => {
    const Item = ({ name, letters }) => {
      const lastIndexLetter = letters.length - 1;

      const Letter = ({ icon, orSeparator }) => {
        const props = {
          type: ButtonType.OUTLINED,
          isClickable: false
        };

        return (
          <>
            {icon !== "" ?
              <IconButton icon={icon} {...props} />
              :
              <Button {...props}>Нет буквы</Button>
            }
            {orSeparator && <span>или</span>}
          </>
        );
      }

      return (
        <li className={styles.campusItem}>
          {name}
          <div className={styles.campusItemLetters}>
            {letters?.map((letter, index) => {
              return <Letter icon={letter} orSeparator={index !== lastIndexLetter} key={index} />;
            })}
          </div>
        </li>
      );
    }

    return (
      <ul className={styles.tabList}>
        <Item
          name={"Главный учебный корпус (ГУК), Советский проспект, 1"}
          letters={["", "г"]}
        />
        <Item
          name={"Учебный корпус № 1, ул. Профессора Баранова, 43 "}
          letters={["б"]}
        />
        <Item
          name={"Учебный корпус № 2, Малый переулок, 32 (СК — Спортивный комплекс)"}
          letters={["м", "ск"]}
        />
        <Item
          name={"Учебный корпус № 3 ул. Калязинская, 4"}
          letters={["к"]}
        />
        <Item
          name={"Корпус № 1 БГАРФ, ул. Молодёжная, 6"}
          letters={["а"]}
        />
        <Item
          name={"Корпус № 2 БГАРФ, ул. Озёрная, 30"}
          letters={["а2"]}
        />
        <Item
          name={"Корпус № 3 БГАРФ, ул. Озёрная, 32"}
          letters={["а3"]}
        />
      </ul>
    );
  }

  /**
   * Компонент для отображения расписания проведения ГИА (Государственная итоговая аттестация).
   *
   * @return {Element} Элемент содержимого вкладки "Расписание проведение ГИА".
   * @constructor
   */
  const GIA = ({ items }) => {
    const FilesList = () => {
      const Item = ({ url, title, description }) => {
        return (
          <li className={styles.giaItem}>
            <Link url={url} newTab={true}>
              <div className={styles.giaItemHead}>
                <IconButton icon={"download"} type={ButtonType.TONAL} isClickable={false} />
                {title}
              </div>
              {description}
            </Link>
          </li>
        );
      }

      return (
        <>
          {items ? (
            <ul className={styles.tabList}>
              {items.map((props, index) => {
                return <Item {...props} key={index} />
              })}
            </ul>
          ) : (
            <div className={styles.giaNoTimetableContainer}>
              <Icon name={"calendar-minus"} size={40} />
              <Title level={2}>Расписания пока нет</Title>
            </div>
          )}
        </>
      );
    }

    return isLoading ? <Spinner /> : <FilesList />;
  }

  /**
   * Компонент для отображения расписания звонков.
   *
   * @return {Element} Элемент содержимого вкладки "Расписание звонков".
   * @constructor
   */
  const Bells = () => {
    return (
      <table className={styles.bellsTable}>
        <thead>
        <tr>
          <th>Пара</th>
          <th>1-ая полупара</th>
          <th>2-ая полупара</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>9:00 – 9:40</td>
          <td>9:45 – 10:25</td>
        </tr>
        <tr>
          <td>2</td>
          <td>10:35 – 11:15</td>
          <td>11:20 – 12:00</td>
        </tr>
        <tr>
          <td>3</td>
          <td>12:10 – 12:50</td>
          <td>12:55 – 13:35</td>
        </tr>
        <tr>
          <td>—</td>
          <td colSpan="2">13:35 – 14:15 (перерыв)</td>
        </tr>
        <tr>
          <td>4</td>
          <td>14:15 – 14:55</td>
          <td>15:00 – 15:40</td>
        </tr>
        <tr>
          <td>5</td>
          <td>15:50 – 16:30</td>
          <td>16:35 – 17:15</td>
        </tr>
        <tr>
          <td>6</td>
          <td>17:25 – 18:05</td>
          <td>18:10 – 18:50</td>
        </tr>
        <tr>
          <td>7</td>
          <td>19:00 – 19:40</td>
          <td>19:45 – 20:25</td>
        </tr>
        <tr>
          <td>8</td>
          <td>20:30 – 21:10</td>
          <td>21:15 – 21:55</td>
        </tr>
        </tbody>
      </table>
    );
  }

  /**
   * Компонент для отображения содержимого вкладки.
   *
   * @return {Element} Элемент содержимого вкладки.
   * @constructor
   */
  const Content = () => {
    let scrollClasses = "custom-scroll";

    // Если активна вкладка "Раписание звонков",
    // то дополнительно стилизуем скролл
    if (activeTab === 0) {
      scrollClasses += ` ${styles.bellsScroll}`;
    }

    const classNames = `${styles.tabContent} ${scrollClasses}`;

    return (
      <div className={styles.contentWrapper}>
        <div className={classNames}>
          {tabsContent[activeTab]}
        </div>
      </div>
    );
  }

  const tabsContent = [<Bells />, <Campuses />, <GIA />];

  return (
    <section className={styles.container}>
      <div className={styles.column}>
        <div className={styles.head}>
          <Title tag={"h2"} level={2}>Дополнительные разделы</Title>
          Прочая информация, которая может пригодиться
        </div>
        <Nav />
      </div>
      <div className={styles.column}>
        <Content />
      </div>
    </section>
  );
}

export default Additional;