import React, {useEffect, useRef, useState} from "react";
import styles from "./SearchPage.module.css";
import ListItem from "../../../api/data/ListItem";
import Title from "../../components/Title/Title";
import CustomList from "../../components/CustomList/CustomList";
import Spinner from "../../components/Spinner/Spinner";
import Icon from "../../components/Icon";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import request from "../../../api/utils/request";
import { useAppNav } from "../../../api/hooks/useAppNav";
import { useSearchParams } from "react-router-dom";
import useTitle from "../../../api/hooks/useTitle";
import { useAppContext } from "../../../App";
import SearchInput from "../../components/SearchInput/SearchInput";
import Additional from "./blocks/Additional/Additional";
import Recent from "./blocks/Recent/Recent";
import useRecentStorage from "../../../api/hooks/useRecentStorage";
import TimetableObject from "../../../api/data/TimetableObject";

/**
 * Компонент страницы поиска.
 *
 * @return {Element} Элемент страницы.
 */
const SearchPage = () => {
  const { setErrorMessage } = useAppContext();
  const { navToTimetable } = useAppNav();
  const recentStorage = useRecentStorage();

  const [searchParams] = useSearchParams();
  const [searchObjectName, setSearchObjectName] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isValidSearchQuery, setIsValidSearchQuery] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(true);
  const [resultsListState, setResultsListState] = useState({
    items: [],
    subItems: [],
    lastIndex: -1,
    selectedIndex: -1,
    isFocused: false
  });

  const scrollRef = useRef(null);

  // Устанавливаем заголовок документа
  useTitle("Поиск");

  // Слушаем изменение URL-параметров
  useEffect(() => {
    const query = searchParams.get("q") ?? "";

    setSearchObjectName(query);
    setSearchQuery(query);
  }, [searchParams]);

  // Отслеживаем нажатие клавиш
  useEffect(() => {
    const handleKeyDown = (e) => {
      const key = e.key;

      if (key === "ArrowDown") {
        selectResultsListItem(true);
      } else if (key === "ArrowUp") {
        selectResultsListItem(false);
      } else if (key === "Enter") {
        handleListItemClick();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [resultsListState.selectedIndex, resultsListState.lastIndex]);

  // Отслеживаем изменение значения поля ввода
  useEffect(() => {
    const hasValue = searchQuery.trim().length > 1;

    setIsValidSearchQuery(hasValue);

    if (hasValue) {
      setResponseData(null);
      setIsSearching(true);
      request.search(searchQuery, (response, error) => {
        (response?.result) ? setResponseData(response.result) : setErrorMessage(error);
        setIsSearching(false);
      });
    }

    return () => request.cancel();
  }, [searchQuery, setErrorMessage]);

  // Отслеживаем изменение запрашиваемых данных
  useEffect(() => {
    updateResultsListItems(responseData);
  }, [responseData]);

  // Отслеживаем название запрашиваемого объекта по GET-запросу
  useEffect(() => {
    const items = resultsListState.items;

    if (items.length > 0 && items[0].text.toLowerCase() === searchObjectName.toLowerCase()) {
      handleListItemClick(0);
    }
  }, [resultsListState.items, searchObjectName]);

  /**
   * Извлекает элементы списка из массива указанных объектов.
   *
   * @param {Object[]} objects Массив объектов, из которого необходимо извлечь элементы.
   * @return {ListItem[]} Возвращает массив элементов списка с иконками.
   */
  const parseListItems = (objects) => objects?.map(({ subgroups, name }) => {
    const icon = (subgroups?.length > 0) ? ListItem.ICON_ARROW : ListItem.ICON_NONE;

    return new ListItem(name, icon, null);
  });

  /**
   * Обновляет состояние списка результатов.
   *
   * @param {Object} newState Объект с новыми значениями для обновления состояния.
   */
  const updateResultsListState = (newState) => {
    setResultsListState(prevState => ({
      ...prevState,
      ...newState
    }));
  }

  /**
   * Обновляет список элементов.
   *
   * @param {Object[]} data Данные, из которых необходимо извлечь элементы списка.
   */
  const updateResultsListItems = (data = responseData) => {
    if (data) {
      const items = parseListItems(data);

      updateResultsListState({
        items: items,
        selectedIndex: -1,
        lastIndex: items ? items.length - 1 : -1
      });

      // if (isValidSearchQuery) {
      //   scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
      // }
    }
  }

  /**
   * Убирает фокус со списка результатов.
   */
  const blurResultsList = () => {
    if (resultsListState.isFocused && resultsListState.selectedIndex !== -1) {
      updateResultsListState({
        isFocused: false,
        selectedIndex: -1
      });
    }
  }

  /**
   * Сбрасывает состояние списка результатов.
   */
  const resetResultsList = () => {
    updateResultsListState({ subItems: [] });
    updateResultsListItems();
  }

  /**
   * Выделяет следующий либо предыдущий элемент списка.
   *
   * @param {boolean} nextItem `true` - выделяет следующий элемент, `false` - предыдущий.
   */
  const selectResultsListItem = (nextItem) => {
    if (isValidSearchQuery) {
      setIsInputFocused(false);

      const selectedIndex = resultsListState.selectedIndex;
      const lastIndex = resultsListState.lastIndex;
      const prevIndex = (selectedIndex > 0) ? selectedIndex - 1 : lastIndex;
      const nextIndex = (selectedIndex < lastIndex) ? selectedIndex + 1 : 0;

      updateResultsListState({
        isFocused: true,
        isScrollingDown: nextItem,
        selectedIndex: nextItem ? nextIndex : prevIndex
      });
    }
  }

  /**
   * Обрабатывает переход к объекту расписания по данным.
   *
   * @param {Object} data Данные объекта, которые необходимо обработать.
   */
  const handleTimetableNavigation = (data) => {
    navToTimetable(data["type"], data["id"]);

    const obj = TimetableObject.parse(data);

    recentStorage.addItem(obj);
  }

  /**
   * Обрабатывает нажатие на элемент списка.
   *
   * @param {number} index Индекс нажатого элемента. По умолчанию равен индексу выделенного элемента.
   */
  const handleListItemClick = (index = resultsListState.selectedIndex) => {
    if (resultsListState.subItems.length > 0) {
      // Обрабатываем подпункты элементов списка
      const data = resultsListState.subItems[index];

      if (data) {
        handleTimetableNavigation(data);
      }
    } else {
      // Обрабатываем пункты элементов списка (без подпунктов)
      const data = responseData[index];

      if (data) {
        const subgroups = data["subgroups"];
        const hasSubgroups = subgroups?.length > 0;

        updateResultsListState({ subItems: subgroups });

        if (hasSubgroups) {
          updateResultsListItems(subgroups);
        } else {
          handleTimetableNavigation(data);
        }
      }
    }
  }

  /**
   * Компонент для отображения результатов поиска.
   *
   * @return {Element} Элемент списка либо сообщения об отсутствии результатов.
   */
  const Result = () => {
    /**
     * Компонент для отображения списка результатов поиска.
     *
     * @param {ListItem[]} items Массив отображаемых элементов.
     * @param {boolean} backButton Флаг, указывающий на видимость кнопки "Назад".
     * @param {Function} onItemClick Функция обратного вызова, вызываемая при нажатии на элемент списка.
     * @param {Function} onBackButtonClick Функция обратного вызова, вызываемая при нажатии кнопки "Назад".
     * @return {Element} Элемент списка.
     */
    const List = ({ items, backButton, onItemClick, onBackButtonClick }) => {
      /**
       * Компонент для отображения верхней панели списка.
       *
       * @return {Element} Элемент верхней панели.
       */
      const TopBar = () => {
        return (
          <div className={styles.topBarContainer}>
            {backButton ? (
              <div className={styles.backTitle} onClick={onBackButtonClick}>
                <Icon name={"chevron-left"} size={12} color={"currentColor"} />
                Назад
              </div>
            ) : (
              <div className={styles.resultTitle}>Результаты поиска</div>
            )}
          </div>
        );
      }

      const handleClick = () => {
        updateResultsListState({ isFocused: true });
      }

      return (
        <div className={styles.listContainer} onClick={handleClick}>
          <TopBar />
          <CustomList
            items={items}
            selectedItem={resultsListState.selectedIndex}
            height={"200px"}
            onItemClick={onItemClick}
          />
        </div>
      );
    }

    return (
      <>
        {resultsListState.items.length > 0 ? (
          <List
            items={resultsListState.items}
            backButton={resultsListState.subItems.length > 0}
            onItemClick={handleListItemClick}
            onBackButtonClick={resetResultsList}
          />
        ) : (
          <div className={styles.notFoundContainer}>
            <Title level={2}>Ничего не найдено</Title>
            <div className={styles.notFoundText}>Проверьте, нет ли ошибок в введённом запросе</div>
          </div>
        )}
      </>
    );
  }

  /**
   * Обрабатывает выход курсора за пределы контейнера поиска.
   */
  const handleMouseLeave = () => {
    setResponseData(null);
    setIsValidSearchQuery(false);
  }

  /**
   * Обрабатывает изменение значения в поле ввода поиска.
   *
   * @param {string} newValue Введённое значение.
   */
  const handleInputChange = (newValue) => {
    resetResultsList();
    setSearchQuery(newValue);
  }

  return (
    <>
      <Header />
      <main className={styles.main}>
        <section className={styles.searchContainer} onMouseLeave={handleMouseLeave}>
          <div className={styles.cover}>
            <Title tag={"h1"} level={1}>Поиск расписания</Title>
            <div className={styles.coverText}>
              Введите название учебной группы, ФИО преподавателя либо номер кабинета
            </div>
          </div>
          <div className={styles.inputGroupWrapper} ref={scrollRef}>
            <SearchInput
              defaultValue={searchQuery}
              onChange={handleInputChange}
              onFocus={blurResultsList}
              isFocused={isInputFocused}
            />
            {isValidSearchQuery && (
              <div className={styles.resultContainer}>
                {isSearching ? (
                  <div className={styles.notFoundContainer}>
                    <Spinner />
                  </div>
                ) : (
                  <Result />
                )}
              </div>
            )}
            <Recent />
          </div>
        </section>
        <Additional />
      </main>
      <Footer responsiveWide={false} links={true} />
    </>
  );
}
export default SearchPage;