import { useNavigate } from 'react-router-dom';

/**
 * Хук для осуществления навигации по страницам приложения.
 */
export const useAppNav = () => {
  const navigate = useNavigate();

  /**
   * Выполняет переход на страницу поиска расписания.
   *
   * @param {string} query Текст запроса.
   */
  const navToSearch = (query) => {
    navigate({
      pathname: "/",
      search: `?q=${query}`
    });
  };

  /**
   * Выполняет переход на страницу расписания объекта.
   *
   * @param {string} type Тип объекта.
   * @param {number} id ID объекта.
   * @param {string} weekISO Неделя расписания в формате ISO. По умолчанию равняется пустой строке (текущей неделе).
   */
  const navToTimetable = (type, id, weekISO = "") => {
    let search = `?type=${type}&id=${id}`;

    if (weekISO !== "") {
      search += `&weekISO=${weekISO}`;
    }

    navigate({
      pathname: "/timetable",
      search: search
    });
  };

  return {
    navToSearch,
    navToTimetable
  };
};